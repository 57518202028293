/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.bg-blue {
  background-color: #031537 !important;
}

a.carousel-control-prev,
a.carousel-control-next {
  width: 10vw;
}

#page-landing {
  z-index: -1;
}

/* título no vídeo */

.texto-principal {
  z-index: 99999999999;
  color: white;
  text-align: center;
  position: absolute;
}

/* logo e video div */

.logo-container {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

#video {
  width: 100vw;
  height: 100vh;
  position: relative;
  object-fit: cover;
  opacity: 1;
  z-index: 2;
  background-color: white;
}

#img-padrao {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/imgExtras/pattern.png");
  background-repeat: repeat;
  position: absolute;
  opacity: 0.6;
  z-index: 2;
}

#logo {
  width: 60vw;
  position: absolute;
  opacity: 0.7;
  z-index: 2;
}

.custom-shape-divider-bottom-1630625702 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1630625702 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 64px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1630625702 .shape-fill {
  fill: #ffffff;
}

.opacityTransition {
  width: 102%;
  position: relative;
  height: 464px;
  top: 90%;
  background: linear-gradient(
    to top,
    rgb(255 255 255) 0%,
    rgb(255 255 255) 21%,
    rgb(0 0 0 / -21%) 97%
  );
  /* opacity: 0.7265; */
  transform: translate3d(0px, 0px, 0px);
  visibility: inherit;
}

/* Css da tab ativa */

.ativo-icon1 {
  font-size: 8rem;
  color: var(--color-primary);
}

.js-tabContent section {
  display: none;
}

.js-tabContent section.ativo-icon {
  display: block;
}

/* ------------------ RESPONSIVIDADE ------------------ */

@media (min-width: 2000px) {
  /* Seção N.i.A */
  #particles-container {
    max-height: 1200px;
  }

  /* seção clientes */
  .img-cinza {
    max-width: 200px;
  }

  /* Seção parceiros */
  .parceiros-card .parceiro-card {
    max-height: 650px;
  }

  /* div slide lateral */
  .circulo-slide {
    width: 40px;
    height: 40px;
  }

  .slide-ativo {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 1150px) {
  .contato {
    height: auto;
  }

  .contato .box-cards {
    height: auto;
    width: 100vw;
    flex-direction: column;
  }

  .box-cards .contato-card {
    width: 60vw;
    height: 45vh;
    margin-bottom: 7%;
  }

  .box-cards .contato-card:first-child {
    margin-top: 5%;
  }

  .container-info-contato {
    width: 56vw;
    justify-content: start;
  }
}

@media (max-width: 950px) {
  .lateral-slides {
    display: none;
  }
}

@media (max-width: 700px) {
  /* Seção sobre */
  .fa-suitcase,
  .fa-chart-pie,
  .fa-laptop-code,
  .fa-headset {
    font-size: 2rem;
  }

  .fa-suitcase:hover,
  .fa-chart-pie:hover,
  .fa-laptop-code:hover,
  .fa-headset:hover {
    font-size: 2.5rem;
  }

  .ativo-icon1 {
    font-size: 2.5rem;
  }

  .nav-icons div span {
    font-size: 1.3rem;
  }

  /* Seção Números */
  .map-numbers {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .map-numbers #map img {
    width: 55vw;
    margin-top: 12%;
    margin-bottom: 16%;
  }

  /* Seção Soluções */
  .content-solucoes-industria {
    max-width: 2000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* .solucao {
        width: 90vw;
        padding: 2%;
        height: 45vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 8px 0px;
        box-shadow: rgb(0 0 0 / 20%) -3px 10px 11px, inset rgb(255 255 255 / 9%) 1px 0;
        background: #ffffff;
        margin: 0 3%;
    }

    .solucao-img-resp {
        display: block;
    } */

  .sm-direto {
    display: none;
  }

  .img-sol {
    max-width: 50vw;
    margin: 15% 0;
  }

  .img-sol:nth-child(1) {
    max-width: 50vw;
    margin-top: 16%;
  }

  .solucao-botao {
    margin: 10% 0;
  }

  /* Seção nia */
  .nia {
    height: auto;
  }

  .nia-content {
    flex-direction: column;
  }

  .nia-img {
    width: 80vw;
    margin-top: 10%;
  }

  .nia-img img {
    width: 40vw;
  }

  #particles-container {
    width: 60vw;
    max-height: 40vh;
  }

  .nia-text {
    max-width: 80vw;
    margin: 10% 0;
  }

  /* Seção Comentários */
  .comentario .comentario-content {
    height: 80vh;
    flex-direction: column;
  }

  .comentario .carousel-inner {
    height: 80vh;
    width: 100vw;
  }

  .fa-quote-left {
    font-size: 5rem;
  }

  .pessoa-comentario p.title-default {
    font-size: 3rem;
    margin-bottom: 0;
  }

  .comentario-background {
    height: 80vh;
  }

  .comentario-content .pessoa-comentario {
    max-width: 60vw;
  }

  .pessoa-comentario .title-default {
    margin: 10% 0;
  }

  .pessoa-comentario .cargo-empresa {
    font-size: 1.8rem;
  }

  /* Seção Clientes */
  .clientes .cliente-cards {
    flex-direction: column;
    margin-bottom: 0;
  }

  .cliente-cards .cliente-card {
    width: 30vw;
  }

  .img-cinza {
    max-width: 20vw;
  }

  .nao-usado {
    display: none;
  }

  /* Seção Galeria */
  .galeria-text span {
    font-size: 2.5rem;
  }

  /* seção Parceiros */
  .parceiros-card {
    flex-direction: column;
  }

  .parceiros-card .parceiro-card {
    width: 60vw;
    height: 20vh;
    min-height: 100px;
    padding: 0;
    margin-bottom: 10%;
  }

  .parceiro-card .parceiro-img {
    width: 60vw;
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parceiro-card .parceiro-img img {
    max-width: 50vw;
  }

  .parceiro-text {
    display: none;
  }

  /* Seção Contato */
  .contato .title {
    margin-bottom: 0;
  }

  .title-left .title-default {
    margin: 5%;
  }

  .contato.box-cards {
    flex-direction: column;
  }

  .box-cards .contato-card {
    width: 87vw;
    height: 72vh;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
  }

  .contato-card .title-default {
    margin-bottom: 0;
    text-align: center;
  }

  .contato-card img.qr-img {
    width: 65vw;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 10%;
  }

  .container-info-contato {
    flex-direction: column;
    width: 70vw;
    align-items: flex-start;
  }

  .info-contato {
    width: 56vw;
  }

  .contato-flexbox {
    width: 56vw;
  }
}
