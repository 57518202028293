/*------------------------------configurações padrão--------------------------*/

:root{
  /*variável das cores*/
  --color-background: #ffffff;  /*cinza da plataforma SMGeo Direto */
  --color-background-secondary: #00000029;  /*preto alternativo para textos*/
  --color-background-tertiary: #f5f5f5;  /*cinza quase branco */
  --color-primary: #0e5996; /*azul da logo Niceplanet */
  --color-primary-dark: #2F4858;
  --color-title-primary: #0E4371;
  --color-detalhes-azul-escuro: #031537; /* Para detalhes com azul escuro */
  --color-text-primary: #737373;
  --color-verde-direto: #22845c;  /*verde da plataforma SMGeo Direto*/
  --color-verde-logo-sistema:#3b9b45;
  --color-secondary: #43ad4b;   /*verde da logo Niceplanet */

  font-size: 60%; /*controla as medidas em rem*/

  overflow-x: hidden;

  background-color: var(--color-background);
  
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body{
  /*fonte e cor padrão*/
  font: 500 1.6rem OpenSans; 
  line-height: 2;
  color: var(--color-text-primary);
}



/*fonts*/
@font-face{
  font-family: 'OpenSans';
  src: url("./fonts/OpenSans.ttf");
}

@font-face{
  font-family: 'Roboto';
  src: url("./fonts/Roboto.ttf");
}


/* Código que estiliza detalhe de divisão das sessões */
.custom-shape-divider-bottom-1630625702 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1630625702 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 64px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1630625702 .shape-fill {
  fill: #FFFFFF;
}
