.phone {
  border: 10px solid rgb(0, 0, 0);
  border-width: 10px 7px;
  border-radius: 20px;
  margin: 50px auto;
  overflow: hidden;
  transition: all 2s ease;
  width: 260px;
  height: 568px;
}

.phone iframe {
  border: 0;
  height: 100%;
}
.phone video{
  width: 252px;
  height: 560px;
}
/*Different Perspectives*/

.phone.view_1 {
  transform: rotateX(50deg) rotateY(0deg) rotateZ(-50deg);
  box-shadow: -3px 3px 0 rgb(0, 0, 0), -6px 6px 0 rgb(0, 0, 0),
    -9px 9px 0 rgb(26, 26, 26), -12px 12px 0 rgb(15, 15, 15),
    -14px 10px 20px rgb(24, 24, 24);
}

.phone.view_2 {
  transform: rotateX(0deg) rotateY(-60deg) rotateZ(0deg);
  box-shadow: 5px 1px 0 #bbb, 9px 2px 0 #bbb, 12px 3px 0 #bbb, 15px 4px 0 #bbb,
    0 7px 20px #999;
}

.phone.view_3 {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 0px 3px 0 #bbb, 0px 4px 0 #bbb, 0px 5px 0 #bbb, 0px 7px 0 #bbb,
    0px 10px 20px #666;
}
/*Controls*/

@media (max-width: 900px) {
  #wrapper {
    transform: scale(0.8, 0.8);
  }
}

@media (max-width: 700px) {
  #wrapper {
    transform: scale(0.6, 0.6);
  }
}

@media (max-width: 500px) {
  #wrapper {
    margin-top: -100px;
    transform: scale(0.7, 0.7);
  }
  .phone {
    width: 230px;
    height: 490px;
    margin: 0 auto; 
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .phone video{
    width: 220px;
    height: 480px;
  }
}
